import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
  mainBlack,
  mainRed,
  mainWhite,
  innerWidth,
  lightGrey,
  screen,
} from "../components/common/variables";
import SEO from "../components/seo";
import Collapsible from "react-collapsible";
import SubBanner from "../components/banner/sub";
import Quote from "../components/quote";
import Form from "../components/form";

const Wrapper = styled.div`
  .banner {
    position: relative;
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        text-align: center;
        @media ${screen.small} {
          text-align: left;
        }

        .inner-txt {
          .capital-heading {
            margin: 12px 0 0 0;
            @media ${screen.xsmall} {
              margin: 25px 0 0 0;
            }
          }
        }
      }
    }

    .bg {
      order: -1;
      @media ${screen.small} {
        order: 0;
      }
    }
  }

  .how-support {
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
          padding: 0 30px 0 0;
        }

        .capital-subheading {
          margin: 0 0 22px 0;
          text-align: center;
          padding: 0 22px;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
            padding: 0;
          }
          @media ${screen.small} {
            max-width: 530px;
            text-align: left;
            margin: 17px 0 30px 0;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        .normal-p {
          margin: 0 0 22px 0;
          text-align: center;
          @media ${screen.small} {
            max-width: 530px;
            text-align: left;
          }
        }

        &--right {
          padding: 0;
          @media ${screen.small} {
            margin: 0 0 0 10px;
          }

          .accordion {
            position: relative;
            width: 100%;

            .Collapsible {
              .each-accord-title {
                border-bottom: 2px ${mainRed} solid;
                position: relative !important;
                cursor: pointer;
                font-size: 1.22rem;
                font-weight: 400;
                padding: 16px 0;
                @media ${screen.xsmall} {
                  font-size: 1.4rem;
                }
                @media ${screen.large} {
                  font-size: 1.5rem;
                }

                &__icon {
                  color: ${mainRed};
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  display: block;
                  font-size: 1.3rem;
                  font-weight: 700;
                }
              }

              .each-accord-body {
                margin: 18px 0 0 0;
                padding: 0 0 18px 0;

                p {
                  font-size: 1rem;
                  font-weight: 300;
                  margin: 0 0 20px 0;
                  @media ${screen.large} {
                    font-size: 1.1rem;
                  }
                }
              }
            }
          }

          .bordered-p {
            border-bottom: 2px ${mainRed} solid;
            margin: 0 0 25px 0;
            padding: 0 0 20px 0;
            text-align: center;
            @media ${screen.xsmall} {
              padding: 0 0 32px 0;
              margin: 0 0 50px 0;
              text-align: left;
            }

            p {
              font-size: 1.22rem;
              font-weight: 400;
              @media ${screen.xsmall} {
                font-size: 1.4rem;
              }
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }

            strong {
              font-weight: 600;
            }

            &:last-child {
              border-bottom: none;
              padding: 0;
              margin: 0;
            }
          }
        }

        .btn {
          display: none;
          @media ${screen.small} {
            display: block;
          }

          &--mobile {
            display: block;
            text-align: center;
            margin: 30px 0 0 0;
            @media ${screen.small} {
              display: none;
            }
          }
        }
      }
    }
  }

  .quote {
    background: ${lightGrey};
    border-bottom: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
    }

    .msg {
      color: ${mainBlack};
    }
  }
`;

const VolunteerPage = ({ data }) => {
  const {
    support_description,
    support_heading,
    quote_message,
    quote_author,
    banner_heading,
    banner_image,
    meta_description,
    title_tag,
    groups,
  } = data.content.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <SubBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <p className="red-heading">GET INVOLVED</p>
                <h1 className="capital-heading">{banner_heading.text}</h1>
              </div>
            </div>
          </div>
        </SubBanner>

        <div className="how-support">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">{support_heading.text}</h3>
              <p className="normal-p">{support_description}</p>
            </div>

            <div className="col col--right">
              <div className="accordion">
                {groups.map((item, i) => (
                  <Collapsible
                    key={i}
                    trigger={
                      <h4 className="each-accord-title">
                        {item.heading.text}
                        <span className="each-accord-title__icon">+</span>
                      </h4>
                    }
                    triggerWhenOpen={
                      <h4 className="each-accord-title">
                        {item.heading.text}
                        <span className="each-accord-title__icon">&#8722;</span>
                      </h4>
                    }
                    transitionTime={200}
                  >
                    <div
                      className="each-accord-body"
                      dangerouslySetInnerHTML={{
                        __html: item.description.html,
                      }}
                    />
                  </Collapsible>
                ))}
              </div>
            </div>
          </div>
        </div>

        <Quote
          className="quote"
          message={quote_message}
          author={quote_author}
        />

        <Form heading={"Volunteer for Sydney street choir"} volunteer />
      </Wrapper>
    </Layout>
  );
};

export default VolunteerPage;

export const dataQuery = graphql`
  {
    content: prismicVolunteerPage {
      data {
        banner_heading {
          text
        }
        banner_image {
          alt
          url
        }
        support_heading {
          text
        }
        support_description
        groups {
          heading {
            text
          }
          description {
            html
          }
        }
        quote_message
        quote_author
        title_tag
        meta_description
      }
    }
  }
`;
